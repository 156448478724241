// Tailwind CSS
@import "~tailwindcss/base";
@import "~tailwindcss/components";
@import "~tailwindcss/utilities";

// Fonts
@font-face {
    font-family: 'League Spartan';
    src: url('../fonts/LeagueSpartan-Bold.woff2') format('woff2'),
    url('../fonts/LeagueSpartan-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;1,300;1,400&display=swap');

.react-datepicker__input-container {
  width: inherit;
}

.react-datepicker-wrapper {
  width: 100%;
}

// quill-source-code-button editor
.ql-html-editor > textarea {
	height: 300px !important;
	width: 100% !important;
	background-color: white !important;
	padding: 10px !important;
	color: black !important;
	z-index: 100 !important;
	border: 1px solid black !important;
}

.ql-editor.render-ql-editor {
	padding: 0 !important;
}

.tooltip .tooltiptext {
  visibility: hidden;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

@import './Networking/Networking.scss';

.fc-event-time, .fc-event-title {
	white-space: normal;
}

@import './calendar';
