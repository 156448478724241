@media (max-width: 1280px) {
    .fc-header-toolbar {
        flex-direction: column;
    }

    .fc-timeGridWeek-button,
    .fc-dayGridMonth-button {
        &.fc-button {
            display: none !important;
        }
    }
}
