.networking-container {	
	.overflow-auto {
		direction: rtl; // location of scroll bar (left or right : rtl or ltr)

		> div, > p {
			direction: ltr; // correct direction for everything else
		}
	}

	/* scrollbar */
	::-webkit-scrollbar {
	  width: 10px;
	}
	/* Track */
	::-webkit-scrollbar-track {
	  background: white;
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
	  background: #6B7280; // gray-500
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
	  background: #6B7280; // gray-500
	}
}